import React, { useState } from "react";
import { useFormik, FormikHelpers } from "formik";

import {
  Container,
  Form,
  Input,
  Button,
  Link,
  FormContainer,
  Logo,
  Title,
  Subtitle,
  Row,
  ColumnInput,
} from "./styles";

import LogoImage from "../../assets/images/LOGO-ORANGE.png";
import { useAuth, ISignInPayload } from "../../contexts/auth";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLoading,
} from "react-icons/ai";

interface ILoginValidation {
  email?: string;
  password?: string;
}

const Login: React.FC = () => {
  const { signIn } = useAuth();

  const [password, setPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialValues: ISignInPayload = {
    email: "",
    password: "",
  };

  const handleSubmit = async (
    values: ISignInPayload,
    helpers: FormikHelpers<ISignInPayload>
  ) => {
    setLoading(true);
    await signIn(values);
    setLoading(false);
    helpers.setSubmitting(false);
  };

  const validate = (values: ISignInPayload) => {
    const errors: ILoginValidation = {};

    if (!values.email) {
      errors.email = "* Obrigatório";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Este endereço de e-mail é inválido";
    }

    if (!values.password) {
      errors.password = "* Obrigatório";
    } else if (values.password.length < 8) {
      errors.password = "A senha precisa ter no mínimo 8 dígitos";
    }

    return errors;
  };

  const loginForm = useFormik({
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <FormContainer>
        <Logo src={LogoImage} />
        <Title>Seja bem-vindo(a) a Orange</Title>
        <Subtitle>Por favor, insira suas credenciais de acesso</Subtitle>
        <Form onSubmit={loginForm.handleSubmit} autoComplete="off">
          <Input
            id="email"
            name="email"
            placeholder="Digite seu e-mail..."
            label="E-mail"
            autoComplete="chrome-off"
            value={loginForm.values.email}
            error={loginForm.errors.email}
            onChange={loginForm.handleChange}
          />
          <ColumnInput>
            <button type="button" onClick={() => setPassword(!password)}>
              {password ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </button>
            <Input
              wide="100%"
              id="password"
              name="password"
              placeholder="Digite sua senha..."
              label="Senha"
              autoComplete="chrome-off"
              type={password ? "text" : "password"}
              value={loginForm.values.password}
              error={loginForm.errors.password}
              onChange={loginForm.handleChange}
            />
          </ColumnInput>
          <Button
            disabled={loginForm.isSubmitting}
            variant="filled"
            type="submit"
          >
            Entrar &nbsp;
            {loading && (
              <span>
                <AiOutlineLoading />
              </span>
            )}
          </Button>
          <Row>
            <Link variant="outlined" to={{ pathname: "/confirmar-email" }}>
              Esqueceu a senha?
            </Link>
            <Link variant="outlined" to={{ pathname: "/cadastro" }}>
              Cadastre-se
            </Link>
          </Row>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default Login;
