import React, { useEffect, useState } from "react";
import { useFormik, FormikHelpers } from "formik";
import { useHistory, useLocation } from "react-router-dom";

import {
  Container,
  Form,
  Input,
  Button,
  Link,
  FormContainer,
  Logo,
  Title,
  Subtitle,
  Row,
  ColumnInput,
} from "./styles";

import LogoImage from "../../assets/images/LOGO-ORANGE.png";
import { useAuth, ISignInPayload } from "../../contexts/auth";
import { MaskInput } from "../../components/Input/styles";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLoading,
} from "react-icons/ai";
import { postRegister } from "../../services/register";
import toast from "react-hot-toast";
import Toast from "../../components/Toast";
import { getUserValidate } from "../../services/user";
import * as Validate from "validations-br";

interface IRegisterValues {
  nome?: string;
  email?: string;
  telefone?: string;
  cpfCnpj?: string;
  dataNascimento?: string;
  senha?: string;
  confirm_password?: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Register: React.FC = () => {
  const { signIn } = useAuth();
  const history = useHistory();
  const query = useQuery();
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<string>("");

  const initialValues: IRegisterValues = {
    nome: "",
    email: "",
    telefone: "",
    // cpfCnpj: "",
    // dataNascimento: "",
    senha: "",
    confirm_password: "",
  };

  useEffect(() => {
    const idUser = query.get("id");
    if (idUser) getIdUser(idUser);
  }, []);

  const getIdUser = async (idUser: string) => {
    try {
      const response = await getUserValidate(idUser);

      if (response.result === "OK") {
        setId(idUser);
      } else {
        toast.error("Usuário não encontrado");
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleSubmit = async (
    values: IRegisterValues,
    helpers: FormikHelpers<IRegisterValues>
  ) => {
    setLoading(true);
    try {
      let { nome, email, telefone, senha } = values;
      // dataNascimento = dataNascimento?.replaceAll("/", "-");
      // dataNascimento = dataNascimento?.split("-").reverse().join("-");
      // dataNascimento += "T00:00:00.000Z";
      let idUser = id !== "" ? id : null;

      const result = await postRegister({
        nome,
        email,
        telefone: telefone?.replaceAll("_", ""),
        // cpfCnpj,
        // dataNascimento,
        senha,
        idUser,
      });
      if (result.status === 201) {
        toast.success("Dados enviados com sucesso.");
        toast.success("Verifique seu e-mail.");
        history.push("/login");
        setLoading(false);
      }
    } catch (err: any) {
      console.error("Erro: ", err);
      Toast.show(err.message, "error");
      setLoading(false);
    }
    // signIn(values);
    // helpers.setSubmitting(false);
  };

  const validate = (values: IRegisterValues) => {
    const errors: IRegisterValues = {};

    if (!values.nome) {
      errors.nome = "* Obrigatório";
    }

    if (!values.email) {
      errors.email = "* Obrigatório";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(values.email)
    ) {
      errors.email = "Este endereço de e-mail é inválido";
    }

    if (!values.telefone) {
      errors.telefone = "* Obrigatório";
    }

    // if (!values.cpfCnpj) {
    //   errors.cpfCnpj = "* Obrigatório";
    // } else if (values.cpfCnpj) {
    //   let isValid;
    //   if (values.cpfCnpj.replace(/\D/g, "").length < 12) {
    //     isValid = Validate.validateCPF(values.cpfCnpj);
    //     if (!isValid) {
    //       errors.cpfCnpj = "CPF inválido";
    //     }
    //   } else {
    //     isValid = Validate.validateCNPJ(values.cpfCnpj);
    //     if (!isValid) {
    //       errors.cpfCnpj = "CNPJ inválido";
    //     }
    //   }
    // }

    if (!values.senha) {
      errors.senha = "* Obrigatório";
    } else if (values.senha.length < 8) {
      errors.senha = "A senha precisa ter no mínimo 8 dígitos";
    }

    if (!values.confirm_password) {
      errors.confirm_password = "* Obrigatório";
    } else if (values.confirm_password !== values.senha) {
      errors.confirm_password =
        "O campo confirmar senha precisa ser igual a senha.";
    }

    return errors;
  };

  const registerForm = useFormik({
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <FormContainer>
        <Logo src={LogoImage} />
        <Title>Cadastre-se na Orange</Title>
        <Subtitle>Crie sua conta e automatize suas compras em cripto.</Subtitle>
        <Form onSubmit={registerForm.handleSubmit} autoComplete="off">
          <Input
            id="nome"
            name="nome"
            placeholder="Digite seu nome..."
            label="Nome"
            value={registerForm.values.nome}
            error={registerForm.errors.nome}
            onChange={registerForm.handleChange}
          />
          <Input
            id="email"
            name="email"
            placeholder="Digite seu e-mail..."
            label="E-mail"
            value={registerForm.values.email}
            error={registerForm.errors.email}
            onChange={registerForm.handleChange}
          />
          <Input
            id="telefone"
            name="telefone"
            masked={true}
            mask={"+999 (99) 99999-9999"}
            placeholder="Digite seu telefone..."
            label="Telefone"
            value={registerForm.values.telefone}
            error={registerForm.errors.telefone}
            onChange={registerForm.handleChange}
          />
          {/* <Input
            id="cpfCnpj"
            name="cpfCnpj"
            masked={true}
            mask={"999.999.999-99"}
            placeholder="Digite seu CPF..."
            label="CPF"
            value={registerForm.values.cpfCnpj}
            error={registerForm.errors.cpfCnpj}
            onChange={registerForm.handleChange}
          />
          <Input
            id="dataNascimento"
            name="dataNascimento"
            masked={true}
            mask={"99/99/9999"}
            placeholder="Digite sua data de nascimento..."
            label="Data de Nascimento"
            value={registerForm.values.dataNascimento}
            error={registerForm.errors.dataNascimento}
            onChange={registerForm.handleChange}
          /> */}
          <Row>
            <ColumnInput>
              <button
                className="password"
                type="button"
                onClick={() => setPassword(!password)}
              >
                {password ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </button>
              <Input
                wide="100%"
                id="senha"
                name="senha"
                placeholder="Digite sua senha..."
                label="Senha"
                type={password ? "text" : "password"}
                value={registerForm.values.senha}
                error={registerForm.errors.senha}
                onChange={registerForm.handleChange}
              />
            </ColumnInput>
            <ColumnInput>
              <button
                className="confirmPassword"
                type="button"
                onClick={() => setConfirmPassword(!confirmPassword)}
              >
                {confirmPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </button>
              <Input
                wide="100%"
                id="confirm_password"
                name="confirm_password"
                placeholder="Digite sua senha novamente..."
                label="Confirmação de senha"
                type={confirmPassword ? "text" : "password"}
                value={registerForm.values.confirm_password}
                error={registerForm.errors.confirm_password}
                onChange={registerForm.handleChange}
              />
            </ColumnInput>
          </Row>
          <Button
            disabled={registerForm.isSubmitting}
            variant="filled"
            type="submit"
          >
            Cadastrar &nbsp;
            {loading && (
              <span>
                <AiOutlineLoading />
              </span>
            )}
          </Button>
          <Link variant="outlined" to={{ pathname: "/login" }}>
            Voltar ao Login
          </Link>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default Register;
