import React from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";

import Login from "../screens/Login";
import Layout from "../components/Layout";

import { useAuth } from "../contexts/auth";
import Register from "../screens/Register";
import ConfirmEmail from "../screens/ForgotPassword/confirmEmail";
import ForgotPassword from "../screens/ForgotPassword";

enum AuthRoutes {
  home = "/",
}

enum NonAuthRoutes {
  login = "/login",
  register = "/cadastro",
  confirm_email = "/confirmar-email",
  edit_password = "/redefinir-senha",
}

interface IRoute {
  component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<IRoute> = ({ component, path, exact = false }) => {
  const { signed } = useAuth();
  const Component = component;

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        signed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const OpenRoute: React.FC<IRoute> = ({ component, path, exact = false }) => {
  const { signed } = useAuth();
  const { state } = useLocation() as any;

  const Component = component;
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        !signed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <OpenRoute path={NonAuthRoutes.login} component={Login} />
        <OpenRoute path={NonAuthRoutes.register} component={Register} />
        <OpenRoute
          path={NonAuthRoutes.confirm_email}
          component={ConfirmEmail}
        />
        <OpenRoute
          path={NonAuthRoutes.edit_password}
          component={ForgotPassword}
        />
        <PrivateRoute path={AuthRoutes.home} component={Layout} />
      </Switch>
    </Router>
  );
};

export default Routes;
