import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import ListTable, { IField } from "../../components/ListTable";
import TableIndicados, { INivel } from "../../components/TableIndicados";
import Toast from "../../components/Toast";
import { IToggleMenuOption } from "../../components/ToggleMenuButton";
import { useAuth } from "../../contexts/auth";
import { BASE } from "../../environment";
import useFetch from "../../hooks/useFetch";
import {
  getIndicationsClosed,
  getIndicationsOpen,
} from "../../services/indications";
import { copyToClipboard } from "../../utils/copyToLink";
import { orderCols } from "../../utils/form";
import { Title } from "../Home/styles";
import { IResponse } from "../Orders";
import {
  Content,
  DivCard,
  Footer,
  PaginateContainer,
  RowButtonOrder,
  SelectedContainer,
} from "../Orders/style";
import { Button, Container, Row } from "./styles";

interface IIndications {
  title: string;
  endpoint: string;
  filters: Object;
  radioSelect: boolean;
}

interface IValues {
  totalPedido: number;
  totalReceber: number;
}

const Indications: React.FC<IIndications> = ({
  title,
  endpoint,
  filters,
  radioSelect,
}) => {
  const { user } = useAuth();
  const [Base, setBase] = useState<string>("");
  const provider = useFetch({ endpoint });
  const providerFields = useFetch({ endpoint: `${endpoint}/campos` });
  const [indicationsActive, setIndicationsActive] = useState(true);
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageClosed, setCurrentPageClosed] = useState(1);
  const [indicationsPending, setIndicationsPending] =
    useState<IResponse | null>(null);
  const [indicationsExecuted, setIndicationsExecuted] =
    useState<INivel | null>(null);
  const [filterPending, setFilterPending] = useState({ statusAccount: "" });
  const [filterExecuted, setFilterExecuted] = useState({ statusAccount: "" });
  const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);
  const [quantityPerPage, setQuantityPerPage] = useState<string | null>(null);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [receivable, setReceivable] = useState<IValues>();
  const [received, setReceived] = useState();
  const [colsIndicationsClosed, setColsIndicationsClosed] = useState<
    Array<IField>
  >([]);
  const [growing, setGrowing] = useState({
    item: "",
    asc: "",
  });

  useEffect(() => {
    // if (filterPending.statusAccount !== "") {
    // } else {
    //   getTableFields();
    // }
  }, [filterPending]);

  useEffect(() => {
    getValueIndications();
  }, []);

  useEffect(() => {
    if (BASE === "prod") {
      setBase("https://app.orangedex.io/#/cadastro");
    } else if (BASE === "homolog") {
      setBase("https://orangedex.devaribox.co/#/cadastro");
    } else {
      setBase("http://localhost:3000/#/cadastro");
    }
  }, []);

  const getTableFields = async () => {
    try {
      const id = user?.id;
      if (id) {
        const resultFields = await providerFields.get(filters);
        if (resultFields) {
          const filterFields = resultFields.sort(orderCols);

          const fieldsPending: any = filterFields.filter(
            (item: any) =>
              item.campo !== "situacaoPgto" &&
              item.campo !== "vlrIndicacao" &&
              item.nome !== "Dt solicitação"
          );

          const fieldsClosed: any = filterFields.filter(
            (item: any) =>
              item.campo !== "dtPagamento" && item.campo !== "przGarantia"
          );

          setCols(fieldsPending);
          setColsIndicationsClosed(fieldsClosed);

          const result = await getIndicationsOpen(currentPage, id, filters);
          if (result) {
            const values = result.docs.map((item: any) => item);
            setReceivable(values[0]);
            setIndicationsPending(result);
          }
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const getValueIndications = async () => {
    try {
      const id = user?.id;
      if (id) {
        const result = await getIndicationsClosed(currentPageClosed, id);

        if (result) {
          setIndicationsExecuted(result);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleOrderCols = (value: string) => {
    if (value === "Par" || value === "Quantidade") {
      if (growing.item === value) {
        setGrowing({
          ...growing,
          item: growing.asc == "false" ? "" : value,
          asc: growing.asc == "false" ? "" : "false",
        });
      } else {
        setGrowing({
          ...growing,
          item: value,
          asc: "true",
        });
      }
    }
  };

  const handleChangePage = (page: number) => {
    if (indicationsActive) {
      if (page + 1 !== currentPage) {
        setCurrentPage(page + 1);
      }
    } else {
      if (page + 1 !== currentPageClosed) {
        setCurrentPageClosed(page + 1);
      }
    }
  };

  const handleQuantityPerPage = (value: string) => {
    setQuantityPerPage(value);
  };

  const handlePayment = async () => {
    setLoading(true);
    const ids = itensSelected();
    const idUser = user?.id;

    if (ids && idUser) {
      try {
        // const result = await
      } catch (error) {
        console.error("Erro: ", error);
      }
      setLoading(false);
    }
  };

  const itensSelected = () => {
    const itens: any = [];

    if (indicationsPending) {
      let docs = [...indicationsPending?.docs];
      let selection = [...selectedIndexes];

      selectedIndexes.map((item: any, index: any) => {
        if (item == true) {
          itens.push(docs[index].id);
          selection.splice(index, 1);
        }
      });

      itens.map((item: any) => {
        let itemIndex = docs.findIndex((i: any) => i === item);
        if (itemIndex >= 0) {
          docs.splice(itemIndex, 1);
        }
      });
    }

    if (itens.length > 0) {
      return itens;
    } else {
      Toast.show("Selecione o item que deseja excluir.");
    }
  };

  const handleFilter = (key: string, field: string) => {
    if (indicationsActive) {
      setFilterPending({ ...filterPending, [key]: field });
    } else {
      setFilterExecuted({ ...filterExecuted, [key]: field });
    }
  };

  function isTrue(item: any) {
    return item == false;
  }

  const handlePaymentLink = () => {
    const url = `${Base}/?id=${user?.id}`;
    copyToClipboard(url);
    return Toast.show("O link foi copiado.", "success");
  };

  return (
    <Container>
      <Title>{title}</Title>
      <DivCard>
        <Row className="link">
          <label>
            Link Indicação:{" "}
            <Button className="link" onClick={() => handlePaymentLink()}>
              Copiar Link
            </Button>
          </label>
        </Row>
      </DivCard>
      <DivCard>
        <TableIndicados indicados={indicationsExecuted} />
      </DivCard>




      {/* <DivCard>
        <RowButtonOrder jContent={"space-between"}>
          <div>
            <button
              className="buttonOrder"
              type="button"
              onClick={() => setIndicationsActive(true)}
            >
              <p className={indicationsActive ? "primary" : ""}>
                Pendentes
                {indicationsPending?.total
                  ? `(${indicationsPending.total})`
                  : "(0)"}
              </p>
            </button>
            <button
              className="buttonOrder"
              type="button"
              onClick={() => setIndicationsActive(false)}
            >
              <p className={!indicationsActive ? "primary" : ""}>
                Finalizadas
                {indicationsExecuted?.total
                  ? `(${indicationsExecuted.total})`
                  : "(0)"}
              </p>
            </button>
          </div>
          <Row>
            <div className="value-indication">
              <p>
                Total a Receber:{" "}
                <span>
                  {receivable && receivable.totalPedido
                    ? `$${receivable.totalPedido}`
                    : "$0"}
                </span>
              </p>
            </div>
            <div className="value-indication">
              <p>
                Total Recebido:{" "}
                <span>
                  {receivable && receivable.totalReceber
                    ? `$${receivable.totalReceber}`
                    : "$0"}
                </span>
              </p>
            </div>
          </Row>
        </RowButtonOrder>
      </DivCard>
      <DivCard>
        <Row>
          <p>Filtros</p>
          {indicationsActive ? (
            <form action="" autoComplete="off">
              <div>
                <input
                  type="search"
                  list="accountOpen"
                  name="myAccountOpen"
                  id="myAccountOpen"
                  autoComplete="chrome-off"
                  placeholder="Situação da Conta"
                />
                <datalist id="accountOpen">
                  <option value="Pendente Pagamento" />
                  <option value="Prazo Garantia (7 dias)" />
                  <option value="Cancelado" />
                  <option value="Efetivado" />
                </datalist>
              </div>
            </form>
          ) : (
            <form action="" autoComplete="off">
              <div>
                <input
                  type="search"
                  list="accountClosed"
                  name="myAccountClosed"
                  id="myAccountClosed"
                  autoComplete="chrome-off"
                  placeholder="Situação de Pagamento"
                  onChange={(ev) =>
                    handleFilter("statusAccount", ev.target.value)
                  }
                />
                <datalist id="accountClosed">
                  <option value="Pendente Saque" />
                  <option value="Cancelado" />
                  <option value="Efetivado" />
                </datalist>
              </div>
            </form>
          )}
          {!indicationsActive ? (
            <div>
              <Button wide={"auto"} disabled={selectedIndexes.every(isTrue)}>
                Solicitar Saque
              </Button>
            </div>
          ) : null}
        </Row>
      </DivCard>
      <Content>
        <ListTable
          radioSelect={indicationsActive ? false : radioSelect}
          changeSelectedIndexes={setSelectedIndexes}
          fields={indicationsActive ? cols : colsIndicationsClosed}
          handleOrderCols={handleOrderCols}
          growing={growing}
          onChangePage={handleChangePage}
          currentPage={indicationsActive ? currentPage : currentPageClosed}
          data={
            indicationsActive && indicationsPending
              ? indicationsPending.docs
              : !indicationsActive && indicationsExecuted
              ? indicationsExecuted.docs
              : []
          }
          rowMenuOptions={actionsMenus}
          withFooter={false}
        />
        <Footer>
          <SelectedContainer>
            <p>Linhas por página: &nbsp;</p>
            <select
              name="quantityPerPage"
              id="quantityPerPage"
              onChange={({ target }) => handleQuantityPerPage(target.value)}
            >
              <option value="5">5</option>
              <option value="10" selected>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </SelectedContainer>
          <PaginateContainer>
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={({ selected }) => handleChangePage(selected)}
              pageRangeDisplayed={2}
              pageCount={
                indicationsActive && indicationsPending
                  ? indicationsPending.totalPages
                  : !indicationsActive && indicationsExecuted
                  ? indicationsExecuted.totalPages
                  : 1
              }
              previousLabel="< "
              renderOnZeroPageCount={undefined}
              activeClassName="active-page-item"
            />
          </PaginateContainer>
        </Footer>
      </Content> */}
    </Container>
  );
};

export default Indications;
