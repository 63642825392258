import styled from "styled-components";
import ButtonLayout from "../../components/Button";
import ArrowDown from "../../assets/images/down-arrow.png";
import Close from "../../assets/images/close.png";
import { RowButtonOrder } from "../Orders/style";

interface IButton {
  wide?: string;
  mLeft?: string;
  background?: string;
}

interface ISpace {
  wide?: string;
  padd?: string;
  jContent?: string;
  aItems?: string;
  border?: boolean;
}

export const Container = styled.div`
  width: 100%;

  & > div:nth-of-type(3)::-webkit-scrollbar {
    display: block;
    height: 8px;
    width: 100%;
  }
  & > div:nth-of-type(3)::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid var(--input-border-blurred);
  }
  & > div:nth-of-type(3)::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    padding: 6px 0;
  }
`;

export const Content = styled.div`
  background: var(--form-background);
  color: var(--white);
  overflow: auto;
  padding: 30px 10px 20px 10px;
`;

export const Row = styled.div<ISpace>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) =>
    props.jContent ? props.jContent : "flex-start"};
  align-items: baseline;
  padding: ${(props) => (props.padd ? props.padd : "20px 20px 50px 20px")};
  width: 100%;

  & > .filter {
    display: flex;
    width: 22%;
  }

  & > div > p {
    color: var(--primary-lighter);
    font-size: 1rem;
  }

  & > div > form:nth-of-type(1) > div {
    position: relative;
    display: flex;
    margin-bottom: 10px;

    & > div {
      margin-left: 10px;
      width: 130px;
    }

    & > input {
      background: var(--form-background) url(${ArrowDown});
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 96%;
      border: 1px solid var(--muted);
      border-radius: 6px;
      color: var(--white);
      cursor: pointer;
      font-size: 12px;
      margin-left: 10px;
      padding: 6px 8px 4px 4px;
      width: 130px;
    }
    & > input::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    & > input[type="search"]::-webkit-search-cancel-button {
      position: relative;
      right: 0;
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      background: url(${Close});
      background-repeat: no-repeat;
      background-position: 96%;
      background-size: 10px;
      cursor: pointer;
    }

    & > input::placeholder {
      color: var(--white);
    }
  }

  & > span > svg {
    color: var(--primary);
    font-size: 26px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 730px) {
    align-items: center;

    & > .filter {
      width: 40% !important;
    }
    & > .buttons {
      width: 60% !important;
      overflow-x: hidden;
    }

    & > button:nth-of-type(1) {
      margin-left: 0;
    }

    & > button:nth-of-type(2) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 701px) {
    & > div {
      width: 100%;
    }
  }

  @media (max-width: 665px) {
    flex-direction: column;
    align-items: flex-start;

    & > .buttons {
      align-items: flex-start;
      padding: 10px 0;
      width: 100% !important;
    }
  }

  @media (max-width: 419px) {
    & > div > form:nth-of-type(1) > div {
      & > input[type="search"]::-webkit-search-cancel-button {
        right: 10px;
      }

      & input[type="date"] {
        min-width: 150px;
      }
    }

    & > button:nth-of-type(2) {
      margin-top: 10px;
    }
  }

  @media (max-width: 289px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Column = styled.div<ISpace>`
  border: ${(props) => (props.border ? "1px solid var(--primary)" : "none")};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: ${(props) => (props.wide ? props.wide : "auto")};

  & > label {
    white-space: nowrap;
  }

  & > p {
    margin-left: 20px;
    font-size: 20px;
  }

  & > select {
    width: 300px;
  }

  input[type="number"] {
    width: 300px;
  }

  .disabled {
    color: var(--muted);
  }

  .datalist {
    background: var(--form-background) url(${ArrowDown});
    background-repeat: no-repeat;
    background-position: 96%;
    background-size: 10px;
    width: 300px;
  }

  .datalist::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  .datalist::placeholder {
    color: var(--white);
  }

  .input-group-addon {
    border: 1px solid var(--muted);
    border-radius: 10px;
    margin-left: 2px;
    padding: 4px 8px;
  }

  .input-group {
    display: flex;
    align-content: stretch;
    height: 30px;
  }

  .input-group > input {
    flex: 1 0 auto;
    width: 270px;
  }

  @media (max-width: 375px) {
    & > input,
    & > select,
    & > .input-group {
      width: 100% !important;

      & > input {
        width: 90%;
      }
    }
  }
`;

export const Card = styled.div`
  background: var(--form-background);
  border: 1px solid var(--primary);
  border-radius: 20px;
  box-shadow: 0 0 6px 2px var(--primary);
  margin-right: 20px;
  margin-bottom: 20px;
  min-height: 190px;
  min-width: 270px;
  height: auto;
  width: 280px;
  padding: 10px 14px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 160px;
    width: 100%;

    & > p {
      font-size: 16px;
      color: var(--muted);

      & > span {
        font-size: 18px;
        color: var(--white);
      }
    }

    & > p:nth-of-type(1) {
      margin-top: 8px;
    }

    & > p:nth-of-type(2) {
      margin-bottom: 10px;
    }

    & > div {
      justify-content: space-between;
      align-items: center;
      padding: 0;
    }
  }
`;

export const TitleCard = styled.h3`
  width: 90%;
`;

export const ButtonCard = styled(ButtonLayout)`
  background: var(--alert);
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 800;
  max-width: 50%;
  width: auto;
  /* text-shadow: 2px 2px 2px var(--muted); */

  @supports (-webkit-text-stroke: 1px var(--muted)) {
    -webkit-text-stroke: 1px var(--muted);
    -webkit-text-fill-color: var(--white);
  }
`;

export const ButtonTrash = styled.button`
  background: transparent;
  border: none;
  color: var(--muted);
  cursor: pointer;
  margin-right: 8px;

  & > svg {
    font-size: 28px;
  }
`;

export const RowButton = styled(RowButtonOrder)`
  width: auto;
  max-width: 100%;

  & > div {
    width: auto;
  }

  @media (max-width: 657px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    align-items: baseline;
    width: 100%;

    &::-webkit-scrollbar {
      display: block;
      height: 6px;
      width: 100%;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border: 1px solid var(--input-border-blurred);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 20px;
      padding: 6px 0;
    }
  }

  @media (max-width: 464px) {
    span {
      margin-right: 10px;
    }
  }

  @media (max-width: 443px) {
    .rowButton {
      min-width: 300px;
    }
  }

  @media (max-width: 419px) {
    padding: 10px 0;

    & > .rowButton {
      flex-direction: column;
      min-width: inherit;
      width: 100%;

      & > button:nth-of-type(1) {
        margin-bottom: 10px;
      }

      & > button {
        margin-left: 0;
        width: 96%;
      }
    }
  }
`;

export const RowButtonAlert = styled(RowButtonOrder)`
  flex-wrap: wrap;

  @media (max-width: 730px) {
    flex-direction: row;
  }
`;

export const Button = styled(ButtonLayout)<IButton>`
  background: ${(props) =>
    props.background ? props.background : "var(--primary)"};
  margin-left: ${(props) => (props.mLeft ? props.mLeft : "auto")};
  width: ${(props) => (props.wide ? props.wide : "100%")};

  @media (max-width: 419px) {
    margin-top: 0 !important;
  }
`;

export const FormModal = styled.div`
  select,
  input {
    background: var(--form-background);
    border: 1px solid var(--muted);
    border-radius: 6px;
    color: var(--white);
    padding: 4px;
  }
  @media (max-width: 730px) {
    .rowModalButtons {
      justify-content: center;
    }
  }

  @media (max-width: 665px) {
    .rowModalButtons {
      flex-direction: row;
    }
  }

  @media (max-width: 321px) {
    .rowModalButtons > button {
      margin-bottom: 10px;
      margin-right: 0 !important;
      margin-left: 0;
      width: 100%;
    }
  }
`;
