import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Menu,
  Div,
  InputSearch,
  ImageUser,
  Logo,
  Button,
} from "./styles";
import { IoMenu, IoSearchSharp, IoNotifications } from "react-icons/io5";
import LogoImage from "../../assets/images/LOGO-ORANGE.png";
import User from "../../assets/images/user.png";

import { useMenu } from "../../contexts/menu";
import { useAuth } from "../../contexts/auth";
import { FiSettings } from "react-icons/fi";

const Header: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { toggleLeftMenu, toggleRightMenu } = useMenu();

  return (
    <Container>
      <Div>
        <Menu onClick={toggleLeftMenu}>
          <IoMenu size={24} color="var(--primary-text-color)" />
        </Menu>
        <Logo
          onClick={() => history.push("/")}
          src={LogoImage}
          alt="Logo da Orange na cor laranja seguida do nome da empresa"
        />
      </Div>
      {/* <Div>
        <InputSearch placeholder="Pesquise..." />
        <IoSearchSharp style={{ marginLeft: -25, color: 'var(--muted)', cursor: 'pointer', fontSize: '1.2rem' }} />
      </Div> */}
      <Div>
        {/* <Button onClick={() => toggleRightMenu('notificação')}>
          <IoNotifications size={24} color="var(--primary-text-color)" />
        </Button> */}
        <Button onClick={() => toggleRightMenu("perfil")}>
          <ImageUser src={user?.foto || User} />
          {/* <FiSettings /> */}
        </Button>
      </Div>
    </Container>
  );
};

export default Header;
