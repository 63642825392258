import { IconType } from "react-icons";
import { IoHome, IoPerson } from "react-icons/io5";
import { BsFiles, BsPlayCircle } from "react-icons/bs";
import { HiOutlineClipboardList, HiUsers } from "react-icons/hi";
import { MdOutlineNotificationsActive, MdSupportAgent } from "react-icons/md";
import { TbReportMoney } from "react-icons/tb";

export interface IMenuOption {
  id: string;
  name: string;
  icon?: IconType;
  children?: Array<IMenuOption>;
  disabled: boolean;
  order: number;
  path?: string;
}

export const menu: Array<IMenuOption> = [
  {
    id: "5m431l2mk42m",
    order: 1,
    name: "Página Inicial",
    icon: IoHome,
    path: "/",
    disabled: false,
  },
  {
    id: "4k51m2lk24ml2",
    name: "Usuários",
    order: 2,
    icon: IoPerson,
    path: "/usuarios",
    disabled: false,
  },
  {
    id: "7hrufhwfwiefqh",
    name: "Indicações",
    order: 3,
    icon: HiUsers,
    path: "/indicacoes",
    disabled: false,
  },
  {
    id: "6yn3fnv8wfnd",
    order: 4,
    name: "White List",
    icon: HiOutlineClipboardList,
    path: "/white-list",
    disabled: false,
  },
  {
    id: "2ynwudn3nc45",
    order: 5,
    name: "Ordens",
    icon: BsFiles,
    path: "/ordens-abertas",
    disabled: false,
    // children: [
    //   {
    //     id: '6mldsa98a7sgby89',
    //     name: 'Abertas',
    //     disabled: false,
    //     order: 1,
    //   },
    //   {
    //     id: '1uhd27dsjaos',
    //     name: 'Fechadas',
    //     path: '/ordens-fechadas',
    //     disabled: false,
    //     order: 2,
    //   }
    // ]
  },
  /*{
    id: "6efybwfg2r28f",
    order: 6,
    name: "Alerta de Preços",
    icon: MdOutlineNotificationsActive,
    path: "/alerta-precos",
    disabled: false,
  },*/
  {
    id: "8ueh2n1d2dwq",
    order: 7,
    name: "Financeiro",
    icon: TbReportMoney,
    path: "/financeiro",
    disabled: false,
  },
  /*{
    id: "21nifnidwfjo",
    order: 8,
    name: "Treinamento",
    icon: BsPlayCircle,
    path: "/treinamento",
    disabled: false,
  },*/
  {
    id: "978rjefijwdfne",
    order: 9,
    name: "Suporte",
    icon: MdSupportAgent,
    path: "/suporte",
    disabled: false,
  },
  // {
  //   id: '4k51m2lk24ml2',
  //   name: 'Admin',
  //   order: 5,
  //   icon: IoPerson,
  //   disabled: false,
  //   children: [
  //     {
  //       id: '6mldsa98a7sgby89',
  //       name: 'Cadastros',
  //       disabled: false,
  //       order: 1,
  //       children: [
  //         {
  //           id: 'nF2htCZMDnHQ1',
  //           name: 'Entidades',
  //           path: '/entidades',
  //           disabled: false,
  //           order: 1,
  //         },
  //       ]
  //     },
  //     ]
  // },
  //         {
  //           id: '6pm543ekmk2da',
  //           name: 'Tipos de Documentos',
  //           path: '/tipos-de-documentos',
  //           disabled: false,
  //           order: 1,
  //         },
  //         {
  //           id: 'nF2htCZMDnHQ1',
  //           name: 'Entidades',
  //           path: '/entidades',
  //           disabled: false,
  //           order: 3,
  //         },
  //         {
  //           id: 'nF2htCZMDnHQ2',
  //           name: 'Recursos',
  //           path: '/recursos',
  //           disabled: false,
  //           order: 4,
  //         },
  //         {
  //           id: 'nF2htCZMDnHQ3',
  //           name: 'Unidades Federativas',
  //           path: '/unidades-federativas',
  //           disabled: false,
  //           order: 5,
  //         },
  //         {
  //           id: 'nF2htCZMDnHJJ',
  //           name: 'Municípios',
  //           path: '/municipios',
  //           disabled: false,
  //           order: 6,
  //         },
  //         {
  //           id: 'nF2htCZMDnHQ4',
  //           name: 'Unidades de Medidas',
  //           path: '/unidades-de-medidas',
  //           disabled: false,
  //           order: 7,
  //         },
  //       ]
  //     },
  //     {
  //       id: '6jkn3mklmeklm',
  //       name: 'Permissões e Grupos',
  //       disabled: false,
  //       order: 2,
  //       path: '/permissoes-e-grupos',
  //     },
  //     {
  //       id: '6jkn3mklmeklo',
  //       name: 'Tabela de Regras',
  //       disabled: false,
  //       order: 3,
  //       children: [
  //         {
  //           id: '6jkn3mklmeklp',
  //           name: 'Regras de Classes',
  //           path: '/regras-de-classe',
  //           disabled: false,
  //           order: 1,
  //         },
  //         {
  //           id: '6jkn3mklmeklaa',
  //           name: 'Regras de Núcleos',
  //           path: '/regras-de-nucleo',
  //           disabled: false,
  //           order: 2,
  //         },
  //         {
  //           id: '6jkn3mklmeklbb',
  //           name: 'Regras de Precificação',
  //           path: '/regras-de-precificacao',
  //           disabled: false,
  //           order: 3,
  //         },
  //       ]
  //     },
  //     {
  //       id: '6jkn3mklmeklp',
  //       name: 'Classes Explorer',
  //       path: '/classes-explorer',
  //       disabled: false,
  //       order: 4,
  //     },
  // ]
  // },
  // {
  //   id: '6m5lk4mlk1m',
  //   name: 'Compras',
  //   icon: IoCart,
  //   disabled: false,
  //   order: 3,
  //   children: [
  //     {
  //       id: '6mldsa98a7sgby89',
  //       name: 'Cadastros',
  //       disabled: false,
  //       order: 1,
  //       children: [
  //         {
  //           id: '6pm543ekmk2da',
  //           name: 'Centro de Custos',
  //           path: '/centro-de-custo',
  //           disabled: false,
  //           order: 1,
  //         },
  //         {
  //           id: 'n5l4k213mkl23',
  //           name: 'Depósitos',
  //           disabled: false,
  //           order: 2,
  //           path: '/depositos',
  //         },
  //         {
  //           id: 'n5l4k213mkl24',
  //           name: 'Estabelecimentos',
  //           disabled: false,
  //           order: 3,
  //           path: '/estabelecimentos',
  //         },
  //         {
  //           id: 'n5l4k213mkl25',
  //           name: 'Fornecedores',
  //           disabled: false,
  //           order: 4,
  //           path: '/fornecedores',
  //         },
  //         {
  //           id: 'n5l4k213mkl26',
  //           name: 'Locais de Escrituração',
  //           disabled: false,
  //           order: 5,
  //           path: '/locais-de-escrituracao',
  //         },
  //         {
  //           id: 'n5l4k213mkl27',
  //           name: 'Produtos',
  //           path: '/produtos',
  //           disabled: false,
  //           order: 6,
  //         },
  //         {
  //           id: 'n5l4k213mkl28',
  //           name: 'Serviços',
  //           disabled: false,
  //           order: 7,
  //           path: '/servicos',
  //         },
  //       ]
  //     },
  //     {
  //       id: '6mldsa98a7sgby8b',
  //       name: 'Pedidos de Entrada',
  //       path: '/pedidos-de-entrada',
  //       disabled: false,
  //       order: 2,
  //     },
  //     {
  //       id: '6mldsa98a7sgby8c',
  //       name: 'Pedidos de Serviço',
  //       path: '/pedidos-de-compra-de-servico',
  //       disabled: false,
  //       order: 3,
  //     },
  //     {
  //       id: '56nt5lr43emaaas',
  //       name: 'Condições de Pagamento',
  //       path: '/condicoes-de-pagamento',
  //       disabled: false,
  //       order: 4,
  //     },
  //     {
  //       id: '6mldsa98a7sgby8d',
  //       name: 'Relatórios',
  //       disabled: false,
  //       order: 5,
  //       children: [
  //         {
  //           id: '6mldsa98a7sgby8e',
  //           name: 'Curva ABC',
  //           disabled: false,
  //           order: 1,
  //         },
  //         {
  //           id: '6mldsa98a7sgby8f',
  //           name: 'Pedidos de Entrada',
  //           disabled: false,
  //           order: 2,
  //         }
  //       ]
  //     },
  //     {
  //       id: '6mldsa98a7sgby8g',
  //       name: 'Baixa de Pedido',
  //       path: '/baixa-de-pedido',
  //       disabled: false,
  //       order: 6,
  //     },
  //   ]
  // },
  // {
  //   id: '5tkrlk3m1lk',
  //   name: 'Contábil',
  //   icon: IoWallet,
  //   disabled: false,
  //   order: 4,
  //   children: [
  //     {
  //       id: '5tkrlk3m1ll',
  //       name: 'Plano de Contas',
  //       path: '/plano-de-contas',
  //       disabled: false,
  //       order: 1,
  //     },
  //     {
  //       id: '5tkrlk3m1lm',
  //       name: 'Lançamento Contábil',
  //       path: '/lancamento-contabil',
  //       disabled: false,
  //       order: 2,
  //     },
  //     {
  //       id: '5tkrlk3m1ln',
  //       name: 'Relatórios',
  //       disabled: false,
  //       order: 3,
  //       children: [
  //         {
  //           id: '5tkrlk3m1lo',
  //           disabled: false,
  //           order: 1,
  //           name: 'Livro Diário',
  //         },
  //         {
  //           id: '5tkrlk3m1lp',
  //           disabled: false,
  //           order: 2,
  //           name: 'Razão',
  //         },
  //       ]
  //     },
  //   ]
  // },
  // {
  //   id: '5tkrlk3m1bk',
  //   name: 'Comissionamento',
  //   icon: FaMoneyCheckAlt,
  //   disabled: false,
  //   order: 5,
  //   children: [
  //     {
  //       id: '5tkrlk3m1bl',
  //       name: 'Cadastro de Vendedores',
  //       path: '/vendedores/inserir',
  //       disabled: false,
  //       order: 1,
  //     },
  //     {
  //       id: '5tkrlk3m1bkm',
  //       name: 'Fechamento de Comissão',
  //       disabled: false,
  //       order: 2,
  //     },
  //     {
  //       id: '5tkrlk3m1bko',
  //       name: 'Regras de Comissão',
  //       path: '/regras-de-comissao',
  //       disabled: false,
  //       order: 3,
  //     },
  //     {
  //       id: '5tkrlk3m1bn',
  //       name: 'Relatórios',
  //       disabled: false,
  //       order: 4,
  //       children: [
  //         {
  //           id: '5tkrlk3m1bq',
  //           disabled: false,
  //           order: 1,
  //           name: 'Comissões',
  //         }
  //       ]
  //     },
  //   ]
  // },
  // {
  //   id: '5tkrlk3m1jk',
  //   name: 'Estoque',
  //   icon: GiHandTruck,
  //   disabled: false,
  //   order: 6,
  //   children: [
  //     {
  //       id: '5tkrlk3m1jl',
  //       name: 'Cadastro',
  //       disabled: false,
  //       order: 1,
  //       children: [
  //         {
  //           id: '5tkrlk3m1jm',
  //           order: 1,
  //           disabled: false,
  //           name: 'Lotes'
  //         },
  //         {
  //           id: '5tkrlk3m1jj21mr2mkel1',
  //           order: 2,
  //           disabled: false,
  //           name: 'Cores',
  //           path: '/cores',
  //         },
  //         {
  //           id: '5tkrlk3m1jvhrfned',
  //           order: 3,
  //           disabled: false,
  //           name: 'Tamanhos',
  //           path: '/tamanhos',
  //         },
  //         {
  //           id: '5tkrlk3m1jn',
  //           order: 4,
  //           disabled: false,
  //           name: 'Produtos',
  //           path: '/produtos',
  //         },
  //         {
  //           id: '5142mktn5421mkl2135',
  //           order: 5,
  //           disabled: false,
  //           name: 'Transportadoras',
  //           path: '/transportadoras',
  //         },
  //         {
  //           id: '5tkrlk3m1jo',
  //           order: 6,
  //           disabled: false,
  //           name: 'Depósitos',
  //           path: '/depositos',
  //         },
  //         {
  //           id: '5tkrlk3m1oj',
  //           order: 7,
  //           disabled: false,
  //           name: 'Procedência',
  //           path: '/procedencia',
  //         },
  //       ]
  //     },
  //     {
  //       id: '5tkrlk3m1jp',
  //       name: 'Transferência entre Depósitos',
  //       disabled: false,
  //       order: 2,
  //     },
  //     {
  //       id: '5tkrlk3m1jq',
  //       name: 'Inventário',
  //       path: '/inventario',
  //       disabled: false,
  //       order: 3,
  //     },
  //     {
  //       id: '5tkrlk3m1jr',
  //       name: 'Relatórios',
  //       disabled: false,
  //       order: 4,
  //       children: [
  //         {
  //           id: '5tkrlk3m1js',
  //           disabled: false,
  //           order: 1,
  //           name: 'Saldo de Estoque',
  //           path: '/saldo-estoque'
  //         },
  //         {
  //           id: '5tkrlk3m1jt',
  //           disabled: false,
  //           order: 2,
  //           name: 'Extrato',
  //           path: '/extrato-estoque'
  //         },
  //       ]
  //     },
  //     {
  //       id: '5tkrlk3m1js',
  //       name: 'Requisições',
  //       path: '/requisicoes',
  //       disabled: false,
  //       order: 5,
  //     },
  //   ]
  // },
  // {
  //   id: '6n4klm3dlka',
  //   name: 'Financeiro',
  //   icon: FaCoins,
  //   disabled: false,
  //   order: 7,
  //   children: [
  //     {
  //       id: '6n4klm3dlkb',
  //       name: 'Cadastro',
  //       disabled: false,
  //       order: 1,
  //       children: [
  //         {
  //           id: '6n4klm3dlkc',
  //           order: 1,
  //           disabled: false,
  //           name: 'Centros de Custo',
  //           path: '/centro-de-custo'
  //         },
  //         {
  //           id: '6n4klm3dlkdm2lk1m421kl',
  //           order: 2,
  //           disabled: false,
  //           name: 'Despesas',
  //           path: '/despesas'
  //         },
  //         {
  //           id: '6n4klm3dlkd412lkm3n21',
  //           order: 3,
  //           disabled: false,
  //           name: 'Receitas',
  //           path: '/receitas'
  //         },
  //         {
  //           id: '6n4klm3dlke',
  //           order: 4,
  //           disabled: false,
  //           name: 'Disponíveis',
  //           path: '/disponiveis'
  //         },
  //         {
  //           id: '6n4klm3dlkem4e21kfnt31rm2',
  //           order: 5,
  //           disabled: false,
  //           name: 'Carteiras de Cobrança',
  //           path: '/carteiras-de-cobranca'
  //         },
  //         {
  //           id: '6n4klm3dlketn43o2r',
  //           order: 6,
  //           disabled: false,
  //           name: 'Bandeiras de Cartão',
  //           path: '/bandeiras-de-cartao'
  //         },
  //         {
  //           id: 'f32yh18dun7hdnjnd',
  //           order: 6,
  //           disabled: false,
  //           name: 'Bancos',
  //           path: '/bancos'
  //         },
  //         {
  //           id: '8fwkjfnnqnffwjdk',
  //           order: 7,
  //           disabled: false,
  //           name: 'Funcionários',
  //           path: '/funcionarios'
  //         },
  //       ]
  //     },
  //     {
  //       id: '6n4klm3dlkf',
  //       name: 'Conciliação Bancária',
  //       path: '/conciliacao-bancaria',
  //       disabled: false,
  //       order: 2,
  //     },
  //     {
  //       id: '6n4klm3dlkg',
  //       name: 'Títulos',
  //       path: '/titulos',
  //       disabled: false,
  //       order: 3,
  //     },
  //     {
  //       id: '6n4klm3dlkh',
  //       name: 'Movimentações de Depósitos',
  //       path: '/movimentacao-depositos',
  //       disabled: false,
  //       order: 4,
  //     },
  //     {
  //       id: '56nt5lr43emaccds',
  //       name: 'Condições de Pagamento',
  //       path: '/condicoes-de-pagamento',
  //       disabled: false,
  //       order: 5,
  //     },
  //     {
  //       id: '6n4klm3dlki',
  //       name: 'Relatórios',
  //       disabled: false,
  //       order: 6,
  //       children: [
  //         {
  //           id: '6n4klm3dlkj',
  //           disabled: false,
  //           order: 1,
  //           name: 'Títulos',
  //         },
  //         {
  //           id: '6n4klm3dlkk',
  //           disabled: false,
  //           order: 2,
  //           name: 'Saldo de Conta',
  //         },
  //         {
  //           id: '6n4klm3dlkl',
  //           disabled: false,
  //           order: 3,
  //           name: 'Extrato de Conta',
  //         },
  //       ]
  //     },
  //     {
  //       id: '6n4klm3dlkm',
  //       name: 'Lançamento Financeiro',
  //       path: '/lancamento-financeiro',
  //       disabled: false,
  //       order: 7,
  //     },
  //   ]
  // },
  // {
  //   id: '6n54klm32wka',
  //   name: 'Frente de Loja',
  //   icon: IoStorefront,
  //   disabled: false,
  //   order: 8,
  //   children: [
  //     {
  //       id: '6n54klm32wkb',
  //       name: 'Cadastro',
  //       disabled: false,
  //       order: 1,
  //       children: [
  //         {
  //           id: '6n54klm32wkc',
  //           order: 1,
  //           disabled: false,
  //           name: 'Produtos',
  //           path: '/produtos',
  //         },
  //         {
  //           id: '6n54klm32wkd',
  //           order: 2,
  //           disabled: false,
  //           name: 'Clientes',
  //           path: '/clientes',
  //         },
  //       ]
  //     },
  //     {
  //       id: '6n54klm32wke',
  //       name: 'Condições de Recebimento',
  //       disabled: false,
  //       order: 2,
  //     },
  //     {
  //       id: '6n54klm32wkf',
  //       name: 'PDV',
  //       disabled: false,
  //       order: 3,
  //     },
  //     {
  //       id: '6n4klm3dlki',
  //       name: 'Relatórios',
  //       disabled: false,
  //       order: 4,
  //     },
  //   ]
  // },
  // {
  //   id: '0vshafsd1m2a',
  //   name: 'Fiscal',
  //   icon: RiFileList3Fill,
  //   disabled: false,
  //   order: 9,
  //   children: [
  //     {
  //       id: '6785493kfeafkmskdm',
  //       name: 'Cadastros',
  //       disabled: false,
  //       order: 1,
  //       children: [
  //         {
  //           id: '675843814412maksmdksam',
  //           name: 'CFOP',
  //           path: '/cfop',
  //           disabled: false,
  //           order: 1,
  //         },
  //         {
  //           id: 'ny5jn21k4ml890085784932',
  //           name: 'Procedências',
  //           path: '/procedencias',
  //           disabled: false,
  //           order: 2,
  //         },
  //         {
  //           id: 'ny5jn21k4ml8900gn5l4k2lk',
  //           name: 'Bases de Cálculo',
  //           path: '/bases-de-calculo',
  //           disabled: false,
  //           order: 3,
  //         },
  //         {
  //           id: 'ny5jn21k4ml8900gn4121klmfew',
  //           name: 'Códigos de Tributação',
  //           path: '/codigos-de-tributacao',
  //           disabled: false,
  //           order: 4,
  //         },
  //       ],
  //     },
  //     {
  //       id: '0vshafsd1m2b',
  //       name: 'Regras Fiscais',
  //       path: '/regras-fiscais',
  //       disabled: false,
  //       order: 1,
  //     },
  //     {
  //       id: '0vshafsd1m4122d',
  //       name: 'Regras de CFOP',
  //       path: '/regras-de-cfop',
  //       disabled: false,
  //       order: 2,
  //     },
  //     {
  //       id: '0vshafsd1m2c',
  //       name: 'Relatórios',
  //       disabled: false,
  //       order: 3,
  //       children: [
  //         {
  //           id: '0vshafsd1m2d',
  //           order: 1,
  //           disabled: false,
  //           name: 'Apuração de Impostos'
  //         },
  //         {
  //           id: '0vshafsd1m2e',
  //           order: 2,
  //           disabled: false,
  //           name: 'Registro de Entrada'
  //         },
  //         {
  //           id: '0vshafsd1m2f',
  //           order: 3,
  //           disabled: false,
  //           name: 'Registro de Saída'
  //         },
  //       ]
  //     },
  //     {
  //       id: '0vshafsd1m2d',
  //       name: 'Sequência do Cálculo',
  //       path: '/sequencia-calculo',
  //       disabled: false,
  //       order: 4,
  //     },
  //   ]
  // },
  // {
  //   id: 'n6lk5tn4rmlma',
  //   name: 'Patrimônio',
  //   icon: RiBuilding4Fill,
  //   disabled: false,
  //   order: 10,
  //   children: [
  //     {
  //       id: 'n6lk5tn4rmlmb',
  //       name: 'Cadastros',
  //       disabled: false,
  //       order: 1,
  //       children: [
  //         {
  //           id: 'n6lk5tn4rmlmc',
  //           disabled: false,
  //           order: 1,
  //           name: 'Imobilizados',
  //         },
  //         {
  //           id: 'n6lk5tn4rmlmd',
  //           disabled: false,
  //           order: 2,
  //           name: 'Lotes',
  //         },
  //       ]
  //     },
  //     {
  //       id: 'n6lk5tn4rmlme',
  //       name: 'Controle de Depreciação',
  //       disabled: false,
  //       order: 2,
  //     },
  //     {
  //       id: 'n6lk5tn4rmlmf',
  //       name: 'Tombamentos',
  //       disabled: false,
  //       order: 3,
  //     },
  //   ]
  // },
  // {
  //   id: '56nt5lr43emk',
  //   name: 'Vendas',
  //   icon: GiReceiveMoney,
  //   disabled: false,
  //   order: 11,
  //   children: [
  //     {
  //       id: '56nt5lr43eml',
  //       name: 'Cadastro',
  //       disabled: false,
  //       order: 1,
  //       children: [
  //         {
  //           id: '56nt5lr43emn',
  //           order: 1,
  //           disabled: false,
  //           name: 'Centro de Custos',
  //           path: '/centro-de-custo'
  //         },
  //         {
  //           id: '56nt5lr43emo',
  //           order: 2,
  //           disabled: false,
  //           name: 'Depósitos',
  //           path: '/depositos',
  //         },
  //         {
  //           id: '56nt5lr43emp',
  //           order: 3,
  //           disabled: false,
  //           name: 'Clientes',
  //           path: '/clientes',
  //         },
  //         {
  //           id: '56nt5lr43emq',
  //           order: 4,
  //           disabled: false,
  //           name: 'Estabelecimentos',
  //           path: '/estabelecimentos',
  //         },
  //         {
  //           id: '56nt5lr43emr',
  //           order: 5,
  //           disabled: false,
  //           name: 'Locais de Escrituração',
  //           path: '/locais-de-escrituracao',
  //         },
  //         {
  //           id: '56nt5lr43ems',
  //           order: 6,
  //           disabled: false,
  //           name: 'Metas de Vendas'
  //         },
  //         {
  //           id: '56nt5lr43emt',
  //           order: 7,
  //           disabled: false,
  //           name: 'Vendedores',
  //           path: '/vendedores',
  //         },
  //         {
  //           id: '56nt5lr43emtu',
  //           order: 8,
  //           disabled: false,
  //           name: 'Produtos',
  //           path: '/produtos',
  //         },
  //         {
  //           id: '56nt5lr43emtv',
  //           order: 9,
  //           disabled: false,
  //           name: 'Serviços'
  //         },
  //       ]
  //     },
  //     {
  //       id: '56nt5lr43emx',
  //       name: 'Precificação',
  //       path: '/regras-de-precificacao',
  //       disabled: false,
  //       order: 2,
  //     },
  //     {
  //       id: '56nt5lr43emy',
  //       name: 'Pedidos de Saída',
  //       path: '/pedidos-de-saida',
  //       disabled: false,
  //       order: 3,
  //     },
  //     {
  //       id: '56nt5lr43emz',
  //       name: 'Pedidos de Serviço',
  //       path: '/pedidos-de-venda-de-servico',
  //       disabled: false,
  //       order: 4,
  //     },
  //     {
  //       id: '56nt5lr43ema',
  //       name: 'Faturamento',
  //       path: '/faturamento',
  //       disabled: false,
  //       order: 5,
  //     },
  //     {
  //       id: '56nt5lr43emabjjjs',
  //       name: 'Condições de Pagamento',
  //       path: '/condicoes-de-pagamento',
  //       disabled: false,
  //       order: 6,
  //     },
  //     {
  //       id: '56nt5lr43emb',
  //       name: 'Relatórios',
  //       disabled: false,
  //       order: 6,
  //       children: [
  //         {
  //           id: '56nt5lr43emc',
  //           disabled: false,
  //           order: 1,
  //           name: 'Curva ABC',
  //         },
  //         {
  //           id: '56nt5lr43emd',
  //           disabled: false,
  //           order: 2,
  //           name: 'Faturamentos',
  //         },
  //         {
  //           id: '56nt5lr43eme',
  //           disabled: false,
  //           order: 3,
  //           name: 'Pedidos de Saída',
  //           path: '/relatorio-pedidos-de-saida'
  //         },
  //         {
  //           id: '56nt5lr43emf',
  //           disabled: false,
  //           order: 4,
  //           name: 'Comissões',
  //         },
  //         {
  //           id: '56nt5lr43emg',
  //           disabled: false,
  //           order: 5,
  //           name: 'Metas de Vendas',
  //         },
  //       ]
  //     },
  //   ]
  // },
];

export const menuFinancial: Array<IMenuOption> = [
  {
    id: "5m431l2mk42m",
    order: 1,
    name: "Página Inicial",
    icon: IoHome,
    path: "/",
    disabled: false,
  },
  {
    id: "7hrufhwfwiefqh",
    order: 2,
    name: "Indicações",
    icon: HiUsers,
    path: "/indicacoes",
    disabled: false,
  },
  {
    id: "2ynwudn3nc45",
    order: 3,
    name: "Ordens",
    icon: BsFiles,
    path: "/ordens-abertas",
    disabled: false,
  },
  {
    id: "6efybwfg2r28f",
    order: 4,
    name: "Alerta de Preços",
    icon: MdOutlineNotificationsActive,
    path: "/alerta-precos",
    disabled: false,
  },
  {
    id: "8ueh2n1d2dwq",
    order: 5,
    name: "Financeiro",
    icon: TbReportMoney,
    path: "/financeiro",
    disabled: false,
  },
  {
    id: "978rjefijwdfne",
    order: 6,
    name: "Suporte",
    icon: MdSupportAgent,
    path: "/suporte",
    disabled: false,
  },
];
