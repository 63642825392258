import styled from "styled-components";
import { ILeftSidebarProps, IListLinkProps } from ".";

import ButtonLayout from "../Button";

export const Container = styled.div<ILeftSidebarProps>`
  background-color: var(--form-background);
  border: none;
  border-radius: 30px;
  /* min-width: 50px; */
  height: auto;
  padding-top: 14px;
  /* margin-left: 8px; */
  margin-top: auto;
  margin-bottom: auto;

  /* transition: .2s;
  -webkit-transition: .2s; */

  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.isExpanded ? "flex-start" : "center")};
  /* justify-content: center; */
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;

  position: ${(props) => (props.isExpanded ? "absolute" : "relative")};
  margin-left: ${(props) => (props.isExpanded ? "8px" : "8px")};
  top: ${(props) =>
    props.isExpanded ? "16%" : props.inactive ? "-60px" : "0"};
  min-width: ${(props) => (props.isExpanded ? "160px" : "50px")};
  max-width: ${(props) => (props.isExpanded ? "165px" : "52px")};
  z-index: ${(props) => props.isExpanded && 3};

  & > ul > li:nth-child(1) {
    margin-top: 10px;
  }

  & > ul > li:nth-child(n + 2):nth-child(-n + 9) {
    margin-top: 20px;
    margin-bottom: 12px;
  }

  @media (max-width: 1024px) {
    ${(props) =>
      props.isExpanded
        ? "& {position: absolute; z-index: 999; background-color: var(--primary); color: var(--white);}"
        : ""}
  }

  @media (max-width: 599px) {
    display: ${(props) => (props.isExpanded ? "block" : "none")};
    min-width: ${(props) => (props.isExpanded ? "168px !important" : "50px")};
    max-width: ${(props) => (props.isExpanded ? "172px !important" : "52px")};

    & > ul > li {
      margin-left: 0;
    }
  }
`;

export const ContainerModal = styled(Container)<ILeftSidebarProps>`
  box-shadow: ${(props) =>
    props.isExpanded && "0 0 0.6rem var(--form-selected)"};
  margin-left: ${(props) => (props.isExpanded ? "8px" : "8px")};
  min-width: ${(props) => (props.isExpanded ? "12%" : "50px")};
  position: ${(props) => (props.isExpanded ? "absolute" : "relative")};
  /* top: ${(props) => props.isExpanded && "23%"}; */
  top: 10%;
  bottom: 0;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  z-index: ${(props) => props.isExpanded && 100};

  justify-content: ${(props) => (props.isExpanded ? "flex-start" : "center")};

  @media (max-width: 1450px) {
    /* top: ${(props) => props.isExpanded && "26%"} */
  }

  @media (max-width: 1024px) {
    ${(props) =>
      props.isExpanded
        ? "& {position: absolute; z-index: 100; background-color: var(--primary); color: var(--white);}"
        : ""}
  }

  @media (min-width: 600px) {
    /* display: none; */
  }

  @media (max-width: 599px) {
    & {
      display: flex;
      position: absolute;
      z-index: 3;
      background-color: var(--primary);
      color: var(--white);
      box-shadow: 0 0 0.6rem var(--form-selected);
      margin-left: 8px;
      min-width: ${(props) => (props.isExpanded ? "160px" : "50px")};
      justify-content: flex-start;
    }
  }
`;

export const List = styled.ul<ILeftSidebarProps>`
  list-style: none;
`;

export const ListModal = styled.ul<ILeftSidebarProps>`
  width: ${(props) => (props.isExpanded === false ? "" : "100%")};
`;

export const ListItem = styled.li<IListLinkProps>`
  margin-left: ${(props) => (props.expanded === "true" ? "20px" : "0")};
`;

export const ListLink = styled.button<IListLinkProps>`
  display: inline-flex;
  align-items: center;
  height: ${(props) => (props.expanded === "false" ? "40px" : "32px")};
  ${(props) => (props.expanded === "false" ? "width: 40px;" : "")}
  ${(props) => (props.expanded === "false" ? "justify-content: center;" : "")}
  ${(props) =>
    props.expanded !== "false" ? "padding: 0px 16px 0px 12px;" : ""}
  white-space: nowrap;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;

  border-radius: 24px;
  cursor: pointer;
  margin-left: ${(props) =>
    props.expanded === "false" ? "0" : props.level ? props.level * 20 : "20"}px;
  margin-right: ${(props) =>
    props.expanded === "false" ? "0" : props.level ? props.level * 40 : "48"}px;
  background-color: ${(props) =>
    props.selected ? "var(--primary)" : "transparent"};
  color: ${(props) =>
    props.selected ? "var(--white)" : "var(--primary-text-color)"};

  & svg {
    margin-right: ${(props) => (props.expanded === "false" ? "0px" : "8px")};
    width: ${(props) => (props.expanded === "false" ? "24px" : "18px")};
    height: ${(props) => (props.expanded === "false" ? "24px" : "18px")};
  }

  & > .arrow-down {
    margin-left: 8px;
  }

  &:hover {
    background-color: var(--primary);
    color: var(--white);
  }

  @media (max-width: 1024px) {
    ${(props) =>
      props.expanded === "false"
        ? props.selected
          ? "&{background-color: var(--primary); color: var(--white);}"
          : "&{background-color: transparent; color: var(--primary);}"
        : "&{background-color: transparent; color: var(--white);}"}
    ${(props) =>
      props.expanded === "false"
        ? props.selected
          ? "&{color: var(--white);}"
          : "&{color: var(--primary-text-color)}"
        : "&:hover{background-color: var(--white); color: var(--primary);}"}
  }
`;

export const ListLinkModal = styled(ListLink)`
  height: ${(props) => (props.expanded === "false" ? "40px" : "40px")};
  ${(props) => (props.expanded === "false" ? "width: 40px;" : "width: auto;")}
  ${(props) => (props.expanded === "false" ? "justify-content: center;" : "")}
  margin-left: ${(props) =>
    props.expanded === "false" ? "0" : props.level ? props.level * 20 : "18"}px;
  margin-right: ${(props) =>
    props.expanded === "false" ? "0" : props.level ? props.level * 40 : "20"}px;
  padding: 0px 16px 0px 12px;

  & svg {
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }
`;

export const Button = styled(ButtonLayout)`
  color: var(--primary-text-color);
  font-size: 14px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    color: var(--white);
  }
`;
