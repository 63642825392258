import styled from "styled-components";
import Calendar from "../../assets/images/Calendar.png";
import Button from "../../components/Button";

interface ISkill {
  wide?: string;
  high?: string;
}

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;
  height: auto;
`;

export const Title = styled.p`
  color: var(--white);
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  background: var(--form-background);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 90%;
  padding: 10px;
`;

export const Row = styled.div`
  // border: 1px solid var(--primary-lighter);
  border: 1px solid var(--secondary);
  border-radius: 10px;
  box-shadow: 0 0 4px var(--secondary);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: auto;
  margin-bottom: 10px;
  width: 100%;

  & > hr {
    height: 340px;
    border-left: 2px solid var(--secondary);
    margin: 0px;
  }

  @media (max-width: 1276px) {
    justify-content: center;

    & > div {
      width: 96%;
      max-width: none;
    }

    & > div:nth-of-type(2) {
      border-top-right-radius: 0;
      border-bottom-left-radius: 10px;
    }

    & > hr {
      display: none;
    }
  }

  @media (max-width: 750px) {
    & > div:nth-child(2) {
    }
  }

  @media (max-width: 435px) {
    height: auto;
  }
`;

export const Column = styled.div<ISkill>`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.high ? props.high : "auto")};
  padding: 10px 0;
  width: 100%;

  .infoProfile {
    flex-wrap: nowrap;
  }

  .nameUser {
    display: flex;
    font-size: 22px;
    /* white-space: nowrap; */
  }

  .nameUser > h3 {
    margin-top: 0;
  }

  .saldo {
    font-size: 20px;
    margin-right: 20px;
  }

  .saldo > label {
    color: var(--muted);
  }

  .saldo > p {
		display: flex;
		align-items: center;
	
		& > svg{
		  margin-right: 2px;
		}
	}

  @media (max-width: 1313px) {
    .infoProfile {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 1276px) {
    .graphic {
      height: auto;
    }
  }

  @media (max-width: 599px) {
    .nameUser {
      font-size: 20px;
    }
  }
`;

export const DivProfile = styled.div`
  color: var(--white);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: auto;
  min-width: 500px;
  width: 38%;

  & > div:nth-of-type(2) {
    margin-left: 10px;
  }

  @media (max-width: 599px) {
    min-width: 90%;
    /* height: 210px; */
    & > div {
      /* height: 160px; */
    }

    & > div:nth-of-type(1) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 504px) {
    flex-wrap: wrap;
    /* height: 370px; */

    & > div:nth-of-type(2) {
      margin-left: 0;
    }
  }

  @media (max-width: 375px) {
    & > div:nth-of-type(2) > .nameUser {
      font-size: 18px;
    }
    & > div:nth-of-type(2) > .saldo {
      font-size: 18px;
    }
  }

  @media (max-width: 333px) {
    & > div:nth-of-type(2) > .nameUser > button > svg {
      font-size: 24px;
      margin-left: 4px;
    }
  }

  @media (max-width: 318px) {
    & > div:nth-of-type(2) > .nameUser {
      font-size: 18px;
    }
  }

  @media (max-width: 300px) {
    & > div:nth-of-type(2) > .nameUser {
      font-size: 16px;
    }
    & > div:nth-of-type(2) > .nameUser > button > svg {
      font-size: 22px;
    }
  }
`;

export const DivOrders = styled.div`
  background-color: var(--form-background);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px 0 20px;
  height: auto;
  width: 60%;
  max-width: 60%;

  & > div {
    margin-bottom: 20px;
  }

  & > div:nth-of-type(1) > div > .label {
    margin-bottom: 14px;
  }

  & > div > div {
    background: var(--black);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 92%;
    width: 92%;
  }

  & > div > div > .label {
    background: transparent;
    /* border-radius: 4px; */
    color: var(--white);
    font-size: 28px;
    margin-bottom: 34px;
    padding: 4px 10px;
    width: auto;
    min-height: 40px;
    min-width: 100px;
    text-align: center;
  }

  & > div > div > .field {
    background: var(--form-background);
    border: 1px solid var(--secondary);
    border-radius: 4px;
    box-shadow: 0 0 6px var(--secondary);
    margin-bottom: -7px;
    color: var(--white);
    padding: 4px 10px;
    width: auto;
    min-width: 140px;
    text-align: center;
  }

  .green {
    color: var(--green) !important;
  }

  .red {
    color: var(--warning) !important;
  }

  @media (max-width: 1595px) {
    & > div:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 1313px) {
    width: 100%;
    max-width: 100%;

    & > div:last-child {
      margin-left: 20px;
    }
  }

  @media (max-width: 989px) {
    /* overflow-x: scroll; */
    flex-wrap: wrap;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      display: block;
      height: 8px;
      width: 100%;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border: 1px solid var(--input-border-blurred);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 20px;
      padding: 6px 0;
    }
  }

  @media (max-width: 599px) {
    width: 100% !important;
    & > div {
      /* width: 40%; */
    }
  }

  @media (max-width: 465px) {
    justify-content: space-between;
    & > div {
      margin-left: 0 !important;
      margin-right: 0 !important  ;
      width: 40%;
    }
  }

  @media (max-width: 421px) {
    & > div {
      min-width: inherit;
      min-height: 138px;
      height: 140px;
      width: 46%;
    }
  }

  @media (max-width: 398px) {
    padding-left: 4px;
    padding-right: 4px;
  }

  @media (max-width: 375px) {
    justify-content: center;
    & > div {
      width: 60%;
    }
  }

  @media (max-width: 299px) {
    & > div {
      height: 190px !important;
      min-height: 185px !important;
    }
    & > div > div > .label {
      left: 22%;
    }
    & > div > div > .field {
      left: 18%;
    }
  }

  @media (max-width: 280px) {
    & > div > div > .label {
      left: 18%;
    }
    & > div > div > .field {
      left: 14%;
    }
  }
`;

export const GradientOrange = styled.div`
  background: rgb(251, 159, 77);
  background: linear-gradient(
    90deg,
    rgba(251, 159, 77, 1) 0%,
    rgba(219, 78, 116, 1) 100%
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  min-height: 145px;
  width: 150px;
  min-width: 145px;
`;

export const GradientPink = styled.div`
  background: rgb(219, 78, 116);
  background: linear-gradient(
    90deg,
    rgba(219, 78, 116, 1) 0%,
    rgba(233, 111, 188, 1) 100%
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: 20px;
  height: 150px;
  min-height: 145px;
  width: 150px;
  min-width: 145px;
`;

export const GradientBlue = styled.div`
  background: rgb(98, 81, 162);
  background: linear-gradient(
    90deg,
    rgba(98, 81, 162, 1) 0%,
    rgba(222, 111, 243, 1) 100%
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  height: 150px;
  min-height: 145px;
  width: 150px;
  min-width: 145px;
`;

export const DivImage = styled.div`
  border: 1px solid var();
`;

export const DivChart = styled.div<ISkill>`
  border-top-left-radius: 10px;
  color: var(--white);
  height: 340px;
  margin-left: auto;
  margin-right: auto;
  width: ${(props) => props.wide};
  padding-bottom: 70px;

  & > p {
    margin-top: 8px;
    text-align: center;
  }

  & > div {
    margin: 8px 2px 8px auto;
  }

  & .chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    text-align: center;
    width: 100%;
  }

  & .dailyProfit > div {
    height: 300px !important;
    max-height: 300px !important;
  }

  & > div > div > svg {
    display: flex;
    margin-left: auto;
    height: ${(props) => (props.high ? props.high : "340px")};
    width: 96%;
  }

  .tooltip {
    border-radius: 0.25rem;
    background: #26313c;
    color: #fff;
    padding: 1rem;
    box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
    text-align: left;
  }

  .tooltip > p {
    color: var(--primary-lighter);
    font-weight: 500;
  }

  .recharts-sector {
    stroke: transparent;
  }

  .recharts-legend-item-text {
    color: var(--white) !important;
  }

  /* hiding first and last lines in cartesian grid */
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
    stroke-opacity: 0;
  }

  .recharts-area,
  .recharts-cartesian-grid {
    max-height: 100%;
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 40px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  form {
    display: flex;
    padding-left: 10px;

    & > div {
      margin-left: 10px;
      margin-right: 10px;
    }

    & > div > label > input[type="date"] {
      background: var(--form-background) url(${Calendar});
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: 96%;
      border: 1px solid var(--muted);
      border-radius: 6px;
      color: var(--white);
      margin-top: 4px;
      min-height: 27px;
      min-width: 100px;
      padding: 6px 8px 4px 4px;
    }

    & > div > label > input[type="date"]::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    & > div > label > input[type="date"]::placeholder {
      color: var(--white);
    }
  }

  @media (max-width: 626px) {
    & > .chart {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
  }

  @media (max-width: 460px) {
    & > form {
      flex-direction: column;

      & > div > label > input[type="date"] {
        min-width: 126px;
        padding-left: 0;
        padding-right: 20px;
      }
    }

    & > div {
      height: 210px !important;
    }

    .recharts-responsive-container {
      width: 90% !important;
    }
  }

  @media (max-width: 315px) {
    margin-top: 50px;
  }
`;

export const Card = styled.div`
  /* background: #2d203d; */
  /* border: 6px solid transparent; */
  /* border-radius: 10px; */
  display: flex;
  flex-direction: column;
  height: 110px;
  margin: 8px;
  min-width: 140px;
  padding: 10px;
  width: auto;

  /* &:hover{
    box-shadow: 0 0 0.2em #848587;
    border: 6px solid var(--primary-lighter);
  } */

  h3 {
    color: var(--white);
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
  }

  .content {
    display: flex;
    align-items: center;
    margin: 10px auto;
  }

  .content > p {
    font-size: 1.5rem;
    color: var(--white);
  }

  .green {
    color: var(--green) !important;
  }

  .red {
    color: var(--warning) !important;
  }

  .footer {
    display: flex;
    align-items: center;
  }

  .footer > p {
    color: var(--primary);
    font-size: 1rem;
    font-weight: 500;
    margin-left: 6px;
  }
  .item {
    background: #1e242c; //#31363B
    margin-top: 6px;
    width: 100%;
  }

  .skills {
    background: var(--primary-lighter);
    color: var(--white);
    margin-top: 2px;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: right;
  }

  .first {
    width: 85%;
  }

  .second {
    width: 80%;
  }

  .third {
    width: 65%;
  }

  .fourth {
    width: 82%;
  }
`;

export const ItemSkill = styled.div<ISkill>`
  background: #1e242c; //#31363B
  margin-top: 6px;
  width: 100%;

  .skills {
    background: var(--primary-lighter);
    color: var(--white);
    margin-top: 2px;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: right;
  }

  .first {
    width: 85%;
  }

  .second {
    width: ${(props) => (props.wide ? props.wide : "80%")};
  }

  .third {
    width: 65%;
  }

  .fourth {
    width: 82%;
  }
`;

export const ButtonGraph = styled(Button)`
  margin-top: 10px;
  width: auto;
`;

export const ButtonSvg = styled.button`
  background: transparent;
  margin-top: auto;

  & > svg {
    color: var(--white);
    font-size: 28px;
    margin-left: 10px;
  }
`;
