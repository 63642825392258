import React, { useEffect, useState } from "react";
import {
  Container,
  Content,
  OpacityBackground,
  RoutesContainer,
} from "./styles";

import Header from "../Header";
import LeftSidebar from "../LeftSidebar";
import RightSidebar from "../RightSidebar";
import Footer from "../Footer";

import AuthRoutes from "../../navigation/auth";
import { useMenu } from "../../contexts/menu";
import ModalMenu from "../LeftSidebar/modalMenu";
import useWindowSize from "../../hooks/useWindowSize";
import { useAuth } from "../../contexts/auth";

const Layout: React.FC<any> = (props) => {
  const { user } = useAuth();
  const path = props?.location?.state?.from?.state?.from?.pathname;
  const pathLocation = path ? path : null;
  const [pathname, setPathname] = useState(null);
  const size = useWindowSize();
  const {
    isRightMenuOpen,
    initialRightMenu,
    isLeftMenuOpen: isExpanded,
    toggleRightMenu: toggleMenu,
  } = useMenu();

  useEffect(() => {
    if (pathLocation && user) {
      let pathUser = user.inativo === true ? "/" : pathLocation;
      setPathname(pathUser);
    }
  }, []);

  useEffect(() => {
    initialRightMenu();
  }, []);

  return (
    <Container>
      {isRightMenuOpen ? <OpacityBackground className="transition" /> : null}
      <Header />
      {isExpanded && size && size.width && size.width < 600 ? (
        <ModalMenu />
      ) : null}
      <Content>
        {size && size.width && size.width >= 600 ? <LeftSidebar /> : null}
        <RoutesContainer isExpanded={isExpanded}>
          <AuthRoutes initialPath={pathname} />
        </RoutesContainer>
        <Footer />
      </Content>
      <RightSidebar />
    </Container>
  );
};

export default Layout;
