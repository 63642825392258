import styled from "styled-components";
import ButtonLayout from "../../components/Button";

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;

  & > div:nth-of-type(2)::-webkit-scrollbar {
    display: block;
    height: 8px;
    width: 100%;
  }
  & > div:nth-of-type(2)::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid var(--input-border-blurred);
  }
  & > div:nth-of-type(2)::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    padding: 6px 0;
  }
`;

export const Title = styled.p`
  color: var(--white);
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  background: var(--form-background);
  color: var(--white);
  overflow: auto;
  padding: 20px 16px 30px 16px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & > span > svg {
    color: var(--primary);
    animation: spin 1s linear infinite;
    font-size: 32px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export const Button = styled(ButtonLayout)`
  margin-bottom: 20px;
  margin-left: 10px;
  min-width: 150px;
  width: 16%;
`;
