/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Input, Row, Select } from "./styles";
import ImageInput from "../ImageInput";
import LookupInput from "../LookupInput";
import DateInput from "../DateInput";
import Table from "../Table";
import { getAddressCep } from "../../services/address";

interface ISelectOption {
  id: number;
  name: string;
}
interface IInput {
  mask?: string;
  money?: boolean;
  id: string;
  placeholder: string;
  required?: boolean;
  label: string;
  type?: string;
  endpoint?: string;
  field?: string;
  uf?: string;
  data?: any;
  multi?: boolean;
  isAddress?: boolean;
  filters?: any;
  disabled?: boolean;
  initialState?: any;
  decimalPrecision?: number;
  coin?: string;
  withHour?: boolean;
  onChange?(options: any): void;
  options?: Array<ISelectOption>;
  cols?: any[];
  actions?: string[];
}

interface IField {
  [key: string]: IInput | Array<IInput>;
}

interface IFormsInputProps {
  fields: IField;
  form: any;
}

const FormInputs: React.FC<IFormsInputProps> = ({ fields, form }) => {
  const [formFields, setFormFields] = useState<any>([]);

  useEffect(() => {
    if (fields) {
      treatFields();
    }
  }, [fields, form]);

  const treatFields = () => {
    const elements = [];
    for (const key of Object.keys(fields)) {
      let element;
      if (key.startsWith("row")) {
        const field = fields[key] as Array<IInput>;
        if (field[0].type === "upload") {
          element = (
            <div style={{ width: "100%", display: "flex" }}>
              <div style={{ width: "30%" }}>{treatField(field[0])}</div>
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ width: "100%", display: "flex", gap: 16 }}>
                  {field[1] && treatField(field[1])}
                  {field[2] && treatField(field[2])}
                </div>
                <div style={{ width: "100%", display: "flex", gap: 16 }}>
                  {field[3] && treatField(field[3])}
                  {field[4] && treatField(field[4])}
                </div>
                <div style={{ width: "100%", display: "flex", gap: 16 }}>
                  {field[5] && treatField(field[5])}
                  {field[6] && treatField(field[6])}
                </div>
              </div>
            </div>
          );
        } else {
          element = (
            <Row key={key} amount={Math.floor(100 / field.length) - 1}>
              {field.map((field) => treatField(field))}
            </Row>
          );
        }
      } else if (key.startsWith("tablerow")) {
        const field = fields[key] as Array<IInput>;
        element = field.map((field) => treatField(field));
      } else {
        const field = fields[key] as IInput;
        element = treatField(field);
      }

      elements.push(element);
    }
    setFormFields(elements);
  };
  const treatField = (field: IInput) => {
    switch (field.type) {
      case "select":
        return (
          <Select
            key={field.id}
            label={field.label}
            id={field.id}
            options={field.options}
            require={field.required}
            value={form.values[field.id]}
            error={form.errors[field.id]}
            onChange={form.handleChange}
          />
        );
      case "checkbox":
        return (
          <Input
            key={field.id}
            masked={field.mask !== null}
            mask={field.mask}
            id={field.id}
            name={field.id}
            require={field.required}
            placeholder={field.placeholder}
            label={field.label}
            checked={form.values[field.id]}
            type="checkbox"
            error={form.errors[field.id]}
            onChange={form.handleChange}
          />
        );
      case "upload":
        return (
          <ImageInput
            key={field.id}
            id={field.id}
            name={field.id}
            require={field.required}
            disabled={field.disabled}
            initialState={field.initialState}
            error={form.errors[field.id]}
            onChange={field.onChange}
          />
        );
      case "date":
        return (
          <DateInput
            key={field.id}
            id={field.id}
            name={field.id}
            label={field.label}
            require={field.required}
            value={form.values[field.id]}
            onChange={form.handleChange}
            withHour={field.withHour ? true : false}
          />
        );
      case "lookup":
        return (
          <LookupInput
            key={field.id}
            label={field.label}
            endpoint={field.endpoint || ""}
            field={field.field || ""}
            filters={field.filters}
            multi={field.multi}
            disabled={field.disabled}
            data={field.data}
            require={field.required}
            error={form.errors[field.id]}
            uf={field.uf}
            initialState={field.initialState}
            onChange={field.onChange}
          />
        );
      case "money":
        return (
          <Input
            key={field.id}
            money
            id={field.id}
            name={field.id}
            label={field.label}
            require={field.required}
            decimalPrecision={field.decimalPrecision}
            coin={field.coin}
            value={form.values[field.id]}
            error={form.errors[field.id]}
            onChange={form.handleChange}
          />
        );
      case "table":
        return (
          <Table
            key={field.id}
            title={field.label}
            cols={field.cols}
            actions={field.actions}
            initialValue={form.initialValues[field.id]}
            onChange={field.onChange}
            fromForm
          />
        );
      default:
        return (
          <Input
            key={field.id}
            masked={field.mask !== null}
            money={field.money}
            mask={field.mask}
            id={field.id}
            isAddress={field.isAddress}
            name={field.id}
            require={field.required}
            placeholder={field.placeholder}
            label={field.label}
            value={form.values[field.id]}
            error={form.errors[field.id]}
            onChange={form.handleChange}
          />
        );
    }
  };

  return <>{formFields}</>;
};

export default FormInputs;
