import Toast from "../components/Toast";
import provider from "./config";

export const postRegister = async (payload: any) => {
  const params: any = {
    nome: payload.nome,
    email: payload.email.toLowerCase().trim(),
    telefone: payload.telefone,
    cpfCnpj: payload.cpfCnpj,
    dataNascimento: payload.dataNascimento,
    senha: payload.senha,
  };
  if (payload.idUser) {
    params["idIndicador"] = payload.idUser;
  }

  try {
    const response = await provider.post("/entidades/cadastro", params);
    return response;
  } catch (err: any) {
    Toast.show(err.response.data.message, "error");
    throw Error("Erro ao enviar os dados.");
  }
};
